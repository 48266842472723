import styled, { css } from "styled-components";
import { Link } from "gatsby";
import vars from "../lib/styled";

const commonLink = css`
  background-color: ${(props) => {
    if (props.primary) {
      return vars.primary;
    } else if (props.secondary) {
      return vars.secondary;
    } else {
      return `rgba(255,255,255,0.22)`;
    }
  }};

  border-radius: 30px;
  cursor: pointer;
  color: ${({ primary, secondary }) =>
    primary || secondary ? "#FFF" : vars.primary};
  display: inline-block;
  font-weight: 600;
  line-height: 3rem;
  font-size: 0.833em;
  margin: 0 0.5rem 0 0;
  padding: 0 2rem;
  transition: background-color 500ms ease-in-out;

  &:hover,
  &:focus {
    background-color: ${(props) => {
      if (props.primary) {
        return vars.primaryHover;
      } else if (props.secondary) {
        return vars.secondaryHover;
      } else {
        return `rgba(255,255,255,0.50)`;
      }
    }};
  }
`;

export const ExternalLink = styled.a`
  ${commonLink}
`;

export const Button = styled.button`
  ${commonLink}
  color: #FFF;
`;

export default styled(Link)`
  ${commonLink}
`;
