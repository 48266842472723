import gql from "graphql-tag";

export const LOGIN = gql`
  mutation login($email: Email!, $password: String!) {
    login(email: $email, password: $password) {
      access
      refresh
    }
    
  }
`;

export const LOGOUT = gql`
  mutation logout($jwt: String!) {
    logout(jwt: $jwt) {
      blacklist
    }
  }
`;

export const RESET = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
      ok
    }
  }
`;

export const REFRESH = gql`
  mutation refresh($jwt: String!) {
    refresh(jwt: $jwt) {
      access
    }
  }
`;

export const PROFILE = gql`
  query {
    profile {
      _id
      type
      firstName
      lastName
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $oldPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      ok
    }
  }
`;

export const GET_LOGS = gql`
  query getLogs {
    getLogs {
      email
      date
      timestamp
      log
      referenceId
    }
  }
`;
