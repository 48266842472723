import React from 'react';
import Cookies from 'js-cookie';
import { Mutation } from 'react-apollo';
import { LOGOUT } from '..//queries/account.js';
import { navigate } from 'gatsby';
import { Button } from '../components/Button';

const nullifyAuthHeaders = () => {
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
};

const Logout = () => (
    <Mutation mutation={LOGOUT}>
        {(query, { loading, client }) => (
            <form
                style={{ display: 'inline' }}
                onSubmit={e => {
                    e.preventDefault();
                    query({ variables: { jwt: Cookies.get('refresh_token') } })
                        .then(resp => {
                            nullifyAuthHeaders();
                            window.location.replace('/login');
                        });
                }}>

                <Button type="submit">
                    {loading ? 'Thinking...' : 'Logout'}
                </Button>
            </form>)}
    </Mutation>
);

export default Logout;